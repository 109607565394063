<template>
  <div>
    <div :id="currentId"></div>
  </div>
</template>

<script>
import { randomString } from "@/baseUtils";
import { MD5 } from "@/baseUtils/MD5";
import handleRelyConfig from "~/baseConfig/rely";
import E from "wangeditor";
import i18next from "i18next";
export default {
  props: {
    height: {
      type: Number,
      default: 300,
    },
    width: {
      type: Number,
      default: 500,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentId: randomString(6),
      Editor: null,
      AliClient: new OSS.Wrapper({
        region: handleRelyConfig.ossData.region,
        accessKeyId: handleRelyConfig.ossData.accessKeyId,
        accessKeySecret: handleRelyConfig.ossData.accessKeySecret,
        bucket: handleRelyConfig.ossData.bucket,
      }),
    };
  },
  mounted() {
    this.Editor = new E(`#${this.currentId}`);
    this.Editor.config.showLinkImg = false; /* 隐藏插入网络图片的功能,上传图片的panel不会再显示 */
    this.Editor.config.height = this.height;
    this.Editor.config.placeholder = this.placeholder;
    this.Editor.config.excludeMenus = [
      "emoticon",
      "video",
      "todo",
      "table",
      "code",
    ];
    this.Editor.config.fontSizes = {
      "x-small": { name: "10px", value: "1" },
      small: { name: "12px", value: "2" },
      normal: { name: "14px", value: "3" },
      large: { name: "16px", value: "4" },
      "x-large": { name: "18px", value: "5" },
      "xx-large": { name: "24px", value: "6" },
      "xxx-large": { name: "32px", value: "7" },
    };
    this.Editor.config.customUploadImg = (resultFiles, insertImgFn) => {
      let fileName = `images/${MD5(randomString())}.png`;
      this.AliClient.put(fileName, resultFiles[0])
        .then(function (res) {
          insertImgFn(res.url);
        })
        .catch(function (err) {});
    };
    this.Editor.config.lang = "zh-CN";
    this.Editor.i18next = i18next;
    this.Editor.config.fontNames = [
      "黑体",
      "微软雅黑",
      "Microsoft YaHei",
      "Arial",
      "Tahoma",
      "Verdana",
      "Times New Roman",
      "Courier New",
    ];
    this.Editor.create();
  },
  methods: {
    getContent() {
      let value = this.Editor.txt.html();
      return value;
    },
    setContent(content) {
      if (content) this.Editor.txt.html(content);
    },
  },
};
</script>

<style>
.w-e-toolbar {
  z-index: 2 !important;
}
/* .w-e-menu {
  z-index: 2 !important;
} */
.w-e-text-container {
  z-index: 1 !important;
}
</style>