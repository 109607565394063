<template>
  <div class="baseConsoleTablePage hasTopButton">
    <div class="pageTopButton">
      <div class="filter-content">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input
              size="small"
              v-model="queryParams.name"
              placeholder="请输入产品名称"
            ></el-input>
          </el-col>
          <el-col :span="5">
            <el-select
              size="small"
              v-model="queryParams.type"
              placeholder="全部信息类型"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="5" :offset="9" class="btns">
            <el-button size="small" type="primary" @click="search"
              >查询</el-button
            >
            <el-button size="small" type="default" @click="reset"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </div>
      <div>
        <el-button
          v-if="inspectButton('cos:prd:add')"
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="openAdd"
          >新增产品信息</el-button
        >
      </div>
    </div>
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="100"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <el-table-column prop="date" label="产品名称" min-width="150px">
          <template slot-scope="scope">
            {{ scope.row.name | textFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="信息类型" min-width="80px">
          <template slot-scope="scope">
            <span v-if="scope.row.ptype === 1">图文</span>
            <span v-if="scope.row.ptype === 2">视频</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="是否显示" min-width="80px">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_show"
              active-color="#7ec9f1"
              :active-value="1"
              :inactive-value="0"
              @change="changeAudit($event, scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="发布人" min-width="90px">
          <template slot-scope="scope">
            {{ scope.row.user_name_en|priorFormat(scope.row.user_name_zh,LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="发布时间" min-width="90px">
          <template slot-scope="scope">
            {{ scope.row.created_time | secondFormat }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="120px">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editProduct(scope.row)" v-if="inspectButton('cos:prd:edi')"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="open(scope.row)" v-if="inspectButton('cos:prd:del')"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="10"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="productTitle"
      :visible.sync="addDialog"
      width="1350px"
      :close-on-click-modal="false"
    >
      <addProduct
        :item="currentData"
        v-if="addDialog"
        @closeDialog="closeDialog"
      />
    </el-dialog>
  </div>
</template>

<script>
import addProduct from "~bac/components/company/product/addProduct";
export default {
  name: "companyProduct",
  components: {
    addProduct,
  },
  data() {
    return {
      queryParams: {
        name: "",
        type: "",
      },
      options: [
        {
          label: "图文",
          value: 1,
        },
        {
          label: "视频",
          value: 2,
        },
      ],
      addDialog: false,
      currentData: {},
      productTitle: "",
      tableData: [],
      total: 0,
      currentPage: 1,
      loading: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      let _params = Object.assign({}, this.queryParams);
      Object.keys(_params).forEach((item) => {
        _params[item] = null;
      });
      this.queryParams = _params;
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    openAdd() {
      this.productTitle = "添加产品信息";
      this.currentData = {};
      this.addDialog = true;
    },
    editProduct(data) {
      this.productTitle = "编辑产品信息";
      this.currentData = data;
      this.addDialog = true;
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    closeDialog(value) {
      this.addDialog = false;
      if (value) {
        this.getList();
      }
    },
    async getList() {
      this.loading = true;
      let params = {};
      params.nopage = 0;
      params.start = this.currentPage - 1;
      params.limit = 10;
      params.company_id = this.USER_INFO.company_id;
      if (this.queryParams.name) {
        params.name = this.queryParams.name;
      }
      if (this.queryParams.type) {
        params.ptype = this.queryParams.type;
      }
      let res = await this.$store.dispatch(
        "baseConsole/getProductInfoList",
        params
      );
      console.log(res);
      if (res.success) {
        this.tableData = res.data;
        this.total = res.count;
        this.loading = false;
      }
    },
    async changeAudit(e, item) {
      console.log(e);
      let params = {};
      params.company_id = this.USER_INFO.company_id;
      params.is_show = e;
      params.company_product_id = item.id;
      let res = await this.$store.dispatch(
        "baseConsole/addProductInfoByCompany",
        params
      );
      if (res.success) {
        console.log(res);
        this.$message({
          message: this.$t('changeSuccess'),
          type: "success",
        });
      }
    },
    open(item) {
      console.log(item);
      this.$confirm("确认删除？", "删除", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let params = {};
          params.company_id = this.USER_INFO.company_id;
          params.deleted = 1;
          params.company_product_id = item.id;
          let res = await this.$store.dispatch(
            "baseConsole/addProductInfoByCompany",
            params
          );
          if (res.success) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.filter-content {
  margin-bottom: 10px;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
</style>
