<template>
  <div class="addForm">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
      <el-form-item label="产品名称" prop="name">
        <el-input size="small" placeholder="产品名称" v-model="ruleForm.name" />
      </el-form-item>
      <el-form-item label="信息类型" prop="type">
        <el-select
          size="small"
          style="width:100%;"
          v-model="ruleForm.type"
          placeholder="请选择信息类型"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            >{{ item.label }}</el-option
          >
        </el-select>
      </el-form-item>
      <el-form-item label="产品封面" prop="poster" class="productimg">
        <div class="maskImg">
          <div class="img-wrap">
            <el-image
              v-if="ruleForm.poster"
              style="width: 300px; height: 178px"
              :src="ruleForm.poster"
              :fit="'contain'"
            ></el-image>
          </div>
          <div class="upload">
            <fileUpload
              ref="uploadComponents"
              :accept="'.jpg,.png'"
              :autoUpload="true"
              @uploadCompleteOSS="uploadAssociationLogo"
              @startUploadOSS="startUploadOSS"
            >
              <el-button size="small" type="primary" :loading="imgBtnLoading">上传图片</el-button>
            </fileUpload>
            <div class="tips">
              温馨提示：<br />
              图片尺寸 1080*640，.jpg 或 .png格式，不超过 4M；
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item v-if="ruleForm.type !== 2" label="信息介绍" prop="info">
        <richText ref="richText" :width="1200" :height="250" />
      </el-form-item>
      <el-form-item
        v-if="ruleForm.type === 2"
        label="上传视频"
        prop="videoCoverUrl"
      >
        <div class="maskImg">
          <div class="img-wrap">
            <el-image
              v-if="ruleForm.videoCoverUrl"
              style="width: 300px; height: 178px"
              :src="ruleForm.videoCoverUrl"
              :fit="'contain'"
            ></el-image>
          </div>
          <div class="upload">
            <fileUpload
              ref="uploadVideoComponents"
              :accept="'.mp4, .avi, .rmvb, .ts, .flv, .wmv, video/*'"
              @VODcallbackComplete="VODcallbackComplete"
              :companyId="USER_INFO.company_id"
              :limit="1"
              :isSingleVideo="true"
              :videoUploadAudit="1"
              @startUpdateVideo="startUpdateVideo"
            >
              <el-button size="small" type="primary" :loading="videoBtnLoading">上传视频</el-button>
            </fileUpload>
          </div>
        </div>
      </el-form-item>
      <el-form-item class="btns">
        <el-button size="small" @click="offDialog">取消</el-button>
        <el-button
          size="small"
          :loading="btnLoading"
          type="primary"
          @click="submitForm('ruleForm')"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import richText from "@/baseComponents/richText";
import fileUpload from "@/baseComponents/fileUpload";
export default {
  name: "addProduct",
  components: {
    richText,
    fileUpload,
  },
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      ruleForm: {
        name: "",
        type: null,
        poster: "",
        videoCoverUrl: "",
      },
      rules: {
        name: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        type: {
          required: true,
          message: " ",
          trigger: "change",
        },
        poster: {
          required: true,
          message: "请选择产品封面",
          trigger: "blur",
        },
        videoCoverUrl: {
          required: true,
          message: "请上传视频",
          trigger: "blur",
        },
      },
      options: [
        {
          label: "图文",
          value: 1,
        },
        {
          label: "视频",
          value: 2,
        },
      ],
      btnLoading: false,
      videoInfo: {},
      videoBtnLoading:false,
      imgBtnLoading:false
    };
  },
  mounted() {
    if(this.item.id){
      this.ruleForm.name=this.item.name
      this.ruleForm.type=this.item.ptype
      this.ruleForm.poster=this.item.cover_url
      if(this.item.content){
        this.$refs.richText.setContent(this.item.content)
      }
      if(this.item.video_url){
        this.getAliyunVideo()
      }
    }
  },
  methods: {
    async getAliyunVideo(){
      let res=await this.$store.dispatch("baseConsole/getVideoInfoByAliYunInProduct",{aliyun_id:this.item.video_url})
      console.log(res);
      if(res.success){
        this.ruleForm.videoCoverUrl=res.data.cover_url
        this.videoInfo=res.data
      }
    },
    startUploadOSS(){
      this.imgBtnLoading=true
    },
    uploadAssociationLogo(res) {
      this.imgBtnLoading=false
      this.ruleForm.poster = res.url;
    },
    startUpdateVideo(){
      this.videoBtnLoading=true
    },
    VODcallbackComplete(res) {
      this.videoBtnLoading=false
      this.videoInfo = res;
      this.ruleForm.videoCoverUrl = res.cover_url;
    },
    offDialog() {
      this.$emit("closeDialog");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormFn();
        } else {
          return false;
        }
      });
    },
    async submitFormFn() {
      this.btnLoading = true;
      let params = {};
      console.log(this.videoInfo);
      params.company_id = this.USER_INFO.company_id;
      params.creater = this.USER_ID;
      params.ptype = this.ruleForm.type;
      params.name = this.ruleForm.name;
      params.cover_url = this.ruleForm.poster;
      if (this.ruleForm.type === 1) {
        params.content = this.$refs.richText.getContent();
      } else {
        params.video_url = this.videoInfo.aliyun_id;
      }
      if (this.item.id) {
        params.company_product_id=this.item.id
      }
      let res = await this.$store.dispatch(
        "baseConsole/addProductInfoByCompany",
        params
      );
      console.log(res);
      if (res.success) {
        this.btnLoading = false;
        this.$emit("closeDialog", 1);
      }
    },
  },
};
</script>

<style scoped lang="less">
.productimg {
  margin-bottom: 30px;
}
.maskImg {
  display: flex;

  .img-wrap {
    width: 300px;
    height: 178px;
    background-color: #f2f2f2;
  }

  .upload {
    margin-left: 20px;

    .tips {
      margin-top: 20px;
    }
  }
}
.btns {
  text-align: right;
}
</style>
